import React, { useEffect, useState } from 'react';
import './global.css';
import s from './style.module.css';
import { TVShowAPI } from './api/tv-show';
import { BACKDROP_BASE_URL } from './config';
import TVShowDetail from './components/TVShowDetail/TVShowDetail';
import Logo from './components/Logo/Logo';
import logo from './assets/images/logo.png'
import TVShowList from './components/TVShowList/TVShowList';
import SearchBar from './components/SearchBar/SearchBar';

export default function App() {

  const [ currentTVShow, setCurrentTVShow ] = useState();
  const [ recommendationList, setRecommendationList ] = useState([]);

  async function fetchPopulars(){
    try {
        const populars = await TVShowAPI.fetchPopulars();
        if(populars.length>0){
            setCurrentTVShow(populars[0]);
        }
    }catch(e){
        alert('Erreur durant la recherche des séries populaires ' + e.message);
    }
  }

  async function fetchRecommendations(tvShowId){
    try{
      const recommendations = await TVShowAPI.fetchRecommandations(tvShowId);
      if(recommendations.length>0){
          setRecommendationList(recommendations.slice(0, 10));
      }
    }catch(e){
      alert('Erreur durant la recherche des séries récommandées ' + e.message)
    }
}

  useEffect(() => {
    fetchPopulars();
  }, []);

  useEffect(() => {
    if(currentTVShow){
        fetchRecommendations(currentTVShow.id)
    }
  }, [currentTVShow]);

  console.log('***', currentTVShow);

  // function setCurrentTvShowFromRecommandation(tvShow) {
  //       alert(JSON.stringify(tvShow));
  // }

  async function searchTVShow(tvShowName) {
    try{
      const searchResponse = await TVShowAPI.fetchByTitle(tvShowName);
      if(searchResponse.length > 0){
          setCurrentTVShow(searchResponse[0]);
      }
    }catch(e){
      alert('Erreur durant la recherche de la série ' + e.message)
    }
  }

  return (
    <div className={s.main_container} style={{background: currentTVShow ? `linear-gradient(rgba(0,0,0,0.55), rgba(0,0,0,0.55)), url("${BACKDROP_BASE_URL}${currentTVShow.backdrop_path}") no-repeat center / cover` : 'black' }}>
        <div className={s.header}>
            <div className='row'>
                <div className='col-4'>
                <Logo image={logo} title="Watowatch" subtitle="Find a show you may like" />
                    {/* <div>Logo</div>
                    <div>Subtitle</div> */}
                </div>
                <div className='col-sm-12 col-md-4'>
                    <SearchBar onSubmit={searchTVShow} />
                    {/* <input style={{width: '100%'}} type="text" /> */}
                </div>
            </div>
        </div>
        <div className={s.tv_show_detail}>
            {currentTVShow && <TVShowDetail tvShow={currentTVShow} />}
        </div>
        <div className={s.recommandations}>
          {recommendationList && recommendationList.length > 0 && (
          <TVShowList onClickItem={setCurrentTVShow} tvShowList={recommendationList} />
          )}
          </div>
    </div>
  )
}
